<template>
    <div class="com-lines">
        <ht-chart-line :datas="datas" :x-axis="xAxis" :legend="legend" :colors="colors" :styles="styles" ></ht-chart-line>
    </div>
</template>

<script>
import ajax from '@/ajax.js'
import HtChartLine from '@/components/ht-chart-line/ht-chart-line.vue'
import XDate from '@/utils/x-date.js'
export default {
    name: 'ComLines',
    components: {
        HtChartLine,
    },
    props: {
        /** @brief 工厂方法模式对外暴露的ID，而必须唯一，同时做为路由的binding来使用 */
        name: {
            type: [Number, String],
            default: '0973'
        },
    },
    data(){
        return {
            currentId: null,
            config: null,
            service: null,
            datas: [],
            xAxis: [],
            legend: [],
            colors: [],
            styles: {},
        }
    },
    computed:{
        
    },
    created(){
    },
    mounted(){
        this.init();
        this.downloadId();
        setInterval(() => {
            this.download();
        },5000)
    },
    methods:{
        init(){
            if(!this.name)
                return;
            this.config = require(`./config.${this.name}.js`).default;
            // this.service = require(`./service.${this.name}.js`);
            
            // console.log('init 1:', this.config);
            // console.log('init 2:', this.service);
            
            if(this.config){
                // this.datas = this.config.datas;
                // this.xAxis = this.config.xAxis;
                // this.legend = this.config.legend;
                // this.colors = this.config.colors;
                this.styles = this.config.styles;
                this.legend = ['热端(℃")', '室外温度(℃")'];
                this.colors = [
                    'rgba(255, 0, 127, 1.0)',
                    'RGBA(1, 111, 255, 1.0)',
                ];
                this.styles = {
                    min: -20, // dataMin:数据的最小值，null:自动判断值
                    max: 120, // dataMax:数据的最小值，null:自动判断值
                };
            }
            
            if(this.service && this.service.init){
                this.service.init(this.name);
            }
            
        },
        downloadId(){
            ajax({
                // url: 'https://mttu.muztak.cn/api/overview',
                // url: 'http://eme_system.hzhuishi.cn:8090/api/soldier',
                url: '/api/overview/',
            })
            .then( res =>{
                // console.log("download:", res );
                res.map(it => {
                    this.currentId = it.id;
                    this.download()
                });
                // this.sentries = res;
            });
            
        },
        download(){
            if(this.currentId === null)
                return;
            ajax({
                url: '/api/hot/'+this.currentId,
                method: "get",
                param: {
                    // date: XDate.formatTimestamp('Y-m')
                },
            }).then(list => {
                // console.log("download:", list );
                
                this.xAxis = list.filter(it => it.channel === 0).map(it => {
                    let time = it.timestamp ? XDate.formatTimestamp('H:i:s', XDate.toTimeStamp(it.timestamp.replace('T', ' '))) : '';
                    return time;
                });
                this.datas = [
                    list.filter(it => it.channel === 0).map(it => it.temperature.toFixed(3)),
                    list.filter(it => it.channel === 1).map(it => it.temperature.toFixed(3)),
                ];
                // console.log("download end:", this.xAxis, this.datas)
            });
        },
        update(){
        },
        onMobile(e){
            // console.log("onMobile: ",e );
            this.$store.dispatch('showWindow', 'comDialUp')
            const phone = e.phone;
            this.$store.dispatch('setWindowConfig', {window: 'comDialUp', config: {phone: phone}})
        },
    },
    watch:{
        sentries(val, old){
            // console.log("watch: sentries:", val)
            this.update();
        }
    },
}
</script>


<style>
    .com-lines{width: 100%; height: 200px;}
</style>

