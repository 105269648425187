<template>
    <div :ref="chartId" class="ht-chart-line" >这里什么都没有</div>
</template>

<script>

// import ZjWindow from '@/components/zj-window/zj-window.vue'

import * as echarts from 'echarts';

//添加拆箱方法：对调用对象进行拆箱后再调用：
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

export default {
    props: {
        title: {
            type: String,
            default: "标题"
        },
        xAxis: {
            type: Array,
            default: () => ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'],
        },
        legend: {
            type: Array,
            default: () => ['线1', '线2', ],
        },
        datas: {
            type: Array,
            default: () => [
                [508, 205, 79, 281, 398, 259, 398, 215, 88, 233, 370, 506, 398, ],
                [35, 36, 86, 473, 365, 54, 456, 138, 456, 565, 128, 65, 45, ],
            ],
        },
        colors: {
            type: Array,
            default: () => [
                'rgba(255, 0, 127, 1.0)',
                'rgba(255, 170, 0, 1.0)',
                'rgba(255,208,32,1)',
                'rgba(64,224,144, 1.0)',
                'rgba(1, 253, 255, 1.0)',
                'RGBA(1, 111, 255, 1.0)',
                'RGBA(162, 63, 255, 1.0)',
                'rgba(170, 0, 127, 1.0)',
                'RGBA(246, 94, 176, 1.0)',
            ]
        },
        styles: {
            type: Object,
            default: () => ({
                
                /***** 坐标轴类 *****/
                // axisLine: {
                //     show: true, //显示轴线，默认false
                //     lineStyle: {
                //         color: 'rgba(127,127,127,1)',
                //     }
                // },
                // axisLabel: {
                //     show: true,
                //     color: 'rgba(127,127,127,1)',
                //     fontSize: '9px',
                // },
                // splitLine: {
                //     show: true,
                //     lineStyle: {
                //         color: 'rgba(127,127,127,0.5)',
                //     }
                // },
                // axisTick: { show: true}, // 坐标轴刻度
                // splitNumber: 4, // 分段数估值
                // smooth: true,
                // min: null, // dataMin:数据的最小值，null:自动判断值
                // max: null, // dataMax:数据的最小值，null:自动判断值
                // legend: {
                //     top: '5px',
                //     right: '10px',
                //     textStyle: {
                //         color: "#FFF",
                //         fontSize: 10,
                //     },
                // },
                
                /***** 线型类 *****/
                // symbol: 'circle', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                // symbolSize: 8,
                // lineStyle: {
                //     width: 1,
                // },
                // label: {
                //     show: true,
                //     position: 'top',
                // },
                
                /***** 特殊的后封装样式 *****/
                // "show-areaStyle": true,
            }),
        },
    },
    data(){
        return {
            chartId: 'chart_' + Math.round(Math.random()*10000),
            chart: null,
            chartTimer: null,
        }
    },
    computed:{
    },
    mounted(){
        // console.log("moundted:", this.chartId);
        let container = this.$refs[this.chartId];
        
        this.chart = echarts.init(container);
        // 监听窗口变化
        window.addEventListener('resize', ()=>{
            setTimeout( () => {
                this.chart.resize();
            },1000)
        });
        
        this.updateChart();
    },
    methods:{
        updateChart(){ // 绘制图表
            // vue2的设置选项的方法
            // this.chart.setOption(this.chartOptions(), true);
            // 对调用对象进行拆箱后再调用：
            unwarp(this.chart).setOption(this.chartOptions());
        },
        chartOptions(){
            var options = {
                grid: {
                    top: '10px',
                    left: '2px',
                    right: '15px',
                    bottom: '0px',
                    containLabel: true, //包含内容的尺寸
                    ...this.styles.grid,
                },                
                xAxis: [{
                    type: 'category',
                    axisLabel: this.styles.axisLabel || {show: true},
                    axisLine: this.styles.axisLine || {show: true},
                    splitLine: this.styles.splitLine || {show: false},
                    boundaryGap: false,
                    data: this.xAxis,
                    axisTick: this.styles.axisTick || {show: false},
                }],                            
                yAxis: [{
                    type: 'value',
                    min: this.styles.min || null,
                    max: this.styles.max || null,
                    axisLabel: this.styles.axisLabel || {show: true},
                    axisLine: this.styles.axisLine || {show: true},
                    splitLine: this.styles.splitLine || {show: false},
                    splitNumber: this.styles.splitNumber || 4,
                    axisTick: this.styles.axisTick || {show: false},
                }],
                
                tooltip: {
                    show: true,
                    showContent: true, // 是否显示提示框浮层，默认显示。
                    trigger: 'axis', // 'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
                    textStyle: {
                        color: "#888",
                        fontSize: 8,
                    },
                    axisPointer: {
                        type: 'line', 
                        label: { // 坐标轴指示器的文本标签。
                            show: true,
                            fontSize: 9
                        },
                        snap: true, // 坐标轴指示器是否自动吸附到点上。默认自动判断。
                        lineStyle: {
                            type: 'solid',
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(0, 255, 233,0.2)'
                                }, {
                                    offset: 0.5,
                                    color: 'rgba(255, 255, 255,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(0, 255, 233,0.2)'
                                }],
                                global: false
                            }
                        },
                    },
                },
            
                legend: {
                    show: true,
                    icon: "rect", // 'line', 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none' // 注：新5.2中已经取消了line类型
                    // top: '5px',
                    // right: '10px',
                    itemWidth: 20,
                    itemHeight: 10,
                    textStyle: {
                        color: "rgba(127,127,127,1)",
                        fontSize: 12,
                    },
                    ...this.styles.legend,
                },
                
                dataZoom: {
                    start: 10,
                    type: "inside"
                },
                series: this.datas.map( (data, index) => ({
                    name: this.legend[index],
                    type: 'line',
                    smooth: this.styles.smooth || false, //是否平滑
                    showAllSymbol: true,
                    symbol: this.styles.symbol || 'none', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                    symbolSize: this.styles.symbolSize || 8,
                    itemStyle: {
                        color: this.colors[index],
                    },
                    lineStyle: {
                        width: 2,
                        color: this.colors[index],
                        ...this.styles.lineStyle,
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: this.colors[index],
                        ...this.styles.label,
                    },
                    tooltip: {
                        show: true
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: this.colors[index],
                            },
                            {
                                offset: 1,
                                color: 'rgba(0,0,0,0)'
                            }
                        ], !this.styles['show-areaStyle']),
                    },
                    data: data,
                })),
                
            animation: true,
            }
            return options
        },
    },
    watch: {
        datas(val){
            // console.log("watch: datas:", val);
            this.updateChart();
        },
    },
}
</script>

<style>
    .ht-chart-line {width: 100%; height: 100%;}
</style>