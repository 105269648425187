/** 
 * @brief 通用三维加载模型。
 * 
 * @author Ian.Sieyoo.Zhao       
 * @email ian@pku.edu.cn         
 * @version 1.0.1.200808alpha   
 * @date 2021-06-15             
 * @license GNU General Public License (GPL)  //软件许可协议
 * 
 */
            
const config = {
    datas: [
        [58,28,3],
        [52,4,53],
    ],
    xAxis: [25,26,27],
    legend: ["温度", "K值",],
    colors: [
        'rgba(64,224,144, 1.0)',
        'rgba(1, 253, 255, 1.0)',
        'RGBA(1, 111, 255, 1.0)',
        'RGBA(162, 63, 255, 1.0)',
        'rgba(170, 0, 127, 1.0)',
        'RGBA(246, 94, 176, 1.0)',
    ],
    styles: {
        /***** 线型类 *****/
        // symbol: 'circle', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
        // symbolSize: 5,
        lineStyle: {
            width: 1,
        },
        label: {
            show: false,
            position: 'top',
        },
        min: 'dataMin', // dataMin:数据的最小值，null:自动判断值
        max: 'dataMax', // dataMax:数据的最小值，null:自动判断值
    },
}

export default config;